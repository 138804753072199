<template>
  <div>
    <b-form id="prospecttour" @submit.prevent>
      <b-row class="form-section">
        <b-col>
          <p>
            {{ trans('prospects.landingpage.tour_block.intro') }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row class="mb-3 center-text">
            <b-col cols="2">
              <label for="tour-url" class="font-weight-normal">
                {{ trans('prospects.landingpage.tour_block.url') }}
              </label>
            </b-col>
            <b-col cols="8">
              <b-form-input
                id="tour-url"
                v-model="formData.url"
                :placeholder="trans('prospects.landingpage.tour_block.url_placeholder')"
              >
              </b-form-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <b-button v-if="!saveInProgress" ref="introblockSaveButton" variant="primary" class="margin-top-26 mx-auto my-5" @click="save($event)">
            {{ trans('prospects.landingpage.intro_block.save') }}
          </b-button>
          <b-button v-else variant="primary" class="margin-top-26 mx-auto my-5" :style="{ width: saveButtonWidth }">
            <b-spinner variant="white" />
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  const fb = require('../../../../../../../firebaseConfig')

  export default {
    name: 'LandingpageBlockTour',
    props: {
      block: {
        type: Object,
        required: true
      },
      project: {
        type: Object,
        required: true
      },
      prospect: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        formData: {
          name: this.block.data !== undefined && this.block.data.name !== undefined ? this.block.data.name : '',
          url: this.block.data !== undefined && this.block.data.url !== undefined ? this.block.data.url : ''
        },
        saveInProgress: false,
        saveButtonWidth: false
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile
      })
    },
    methods: {
      save () {
        const self = this
        this.saveButtonWidth = this.$refs.introblockSaveButton ? (parseInt(this.$refs.introblockSaveButton.clientWidth) + 2).toString() + 'px' : false
        this.saveInProgress = true

        this.checkIfBlockExistsInDB().then(() => {
          fb.prospectsCollection.doc(this.prospect._id).update({
            [`landingpage.blocks.${this.block.key}.data`]: this.formData
          }).then(() => {
            self.$bvToast.toast(self.trans('prospects.landingpage.intro_block.save_success'), {
              title: self.trans('global.success'),
              variant: 'success',
              solid: true
            })
            this.saveInProgress = false
          }).catch((err) => {
            self.$bvToast.toast(self.trans('prospects.landingpage.intro_block.save_error'), {
              title: self.trans('global.error'),
              variant: 'warning',
              solid: true
            })
            console.log('There was an error while saving the info for the Intro-block to the DB:')
            console.log(err)
            this.saveInProgress = false
          })
        })
      },
      // checkIfBlockExistsInDB (): "Standard" / Required blocks may not be in DB upon creation...
      checkIfBlockExistsInDB () {
        return new Promise((resolve, reject) => {
          if (this.block.key !== undefined) {
            resolve(true)
          } else {
            const nextBlockId = this.prospect.landingpage !== undefined &&
              this.prospect.landingpage.blocks !== undefined &&
              Object.keys(this.prospect.landingpage.blocks).length > 0
              ? parseInt(Object.keys(this.prospect.landingpage.blocks).pop()) + 1
              : 0

            return fb.prospectsCollection.doc(this.prospect._id).update({
              [`landingpage.blocks.${nextBlockId}`]: {
                createdOn: new Date(),
                deleted: false,
                name: this.block.name.charAt(0).toUpperCase() + this.block.name.slice(1), // Ensure correct naming (camel)
                sortable: false,
                actions: false,
                userId: this.userProfile.uid
              }
            }).then(() => {
              resolve()
            }).catch((err) => {
              reject(err)
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  #prospectintro {
    .row {
      .center-text {
        align-items: center;

        label {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
